.form {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin: 50px;
    padding: 20px;
    width: 1092px;
    border: black solid 1px;
}

.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5rem;
    margin-bottom: 25px;
}

.one-line-field {
    display: flex;
    align-items: center;
    height: 2.8rem;
    margin-right: 15px;
}

.one-line-field > select {
    height: 1.5rem;
    width: 300px;
}

.input {
    height: 1.5rem;
    flex-grow: 1;
}

.name-field {
    font-size: 1rem;
    margin-right: 15px;
    width: 200px;
}

.required {
    font-size: 0.7rem;
    font-style: italic;
    padding-left: 5px;
}

/* .option-container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
} */

.new-container {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    width: 100%;
    margin-bottom: 25px;
}

.client-text {
    margin-right: 15px;
}

.error {
    margin-top: 5px;
    color: red;
}