.container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.inner-container {
    position: relative;
    border: 1px solid black;
    border-radius: 15px;
    padding: 10px;
    margin-bottom: 10px;
    margin-left: 45px;
}

.project {
    margin-bottom: 20px;
}

.username {
    text-align: right;
}

.weekdays {
    position: absolute;
    width: calc(100% - 20px);
    margin-top: 21px;
}

@media screen and (max-width: 1365px) {
    .inner-container {
        margin-left: 0;
        margin-top: 20px;
    }
}
