.container {
    display: flex;
    margin-bottom: 5px;
    align-items: center;
}

.wrapper {
    display: flex;
}

.big-input {
    height: 26px;
    width: 250px;
}

.small-input {
    width: 30px;
    height: 26px;
    text-align: center;
    font-size: 0.8rem;
}

.changed {
    border-color: red !important;
}

@media screen and (max-width: 1365px) {
    .big-input {
        width: 130px;
    }
    
    .small-input {
        width: 50px;
    }
}
