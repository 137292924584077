.container {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    box-sizing: border-box;
    min-width: 640px;
}

.tasks {
    display: flex;
    align-items: flex-start;
    width: 100%;
}

.inner-container {
    width: 100%;
}

.new-tasks {
    width: 100%;
    border: 1px solid black;
    border-radius: 15px;
    padding: 10px;
    margin-top: 20px;
}

.client {
    display: flex;
    flex-direction: column;
}

.button {
    display: flex;
    justify-content: flex-end;
}

.warnings {
    background-color: lightcoral;
    width: 400px;
    border-radius: 5px;
    text-align: center;
}

@media screen and (max-width: 1365px) {
    .tasks {
        flex-direction: column;
        align-items: center;
    }
}
