.input {
    box-sizing: border-box;
    width: 40px;
    text-align: center;
}

/* Chrome, Safari, Edge, Opera */
.input::-webkit-outer-spin-button, .input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.input[type=number] {
    -moz-appearance: textfield;
}

.input:invalid {
    background-color: lightcoral;
}

.updated {
    background-color: yellow;
}

@media screen and (max-width: 1365px) {
    .input {
        width: 30px;
    }
}
