.container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 1092px;
    border: black solid 1px;
}

.container > h3 {
    font-weight: 500;
}

.filters {
    border: 1px dotted;
    padding: 20px;
    margin-bottom: 40px;
    background-color: rgba(0, 0, 0, 0.1);
}

.filters > h3 {
    margin-top: 15px;
}

.filters-heading {
    font-weight: 500;
}

.select-fields {
    display: flex;
    margin-bottom: 15px;
}

.select-fields > label:not(:first-of-type) {
    margin-left: 16px;
}

.select {
    margin: 0 15px;
    width: 300px;
    height: 1.5rem;
}

.info {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.one-line-field {
    display: flex;
    align-items: center;
}

.one-line-field > input {
    flex-grow: 1;
    height: 1.5rem;
}

.name-field {
    font-size: 1rem;
    margin-right: 15px;
    width: 200px;
}

.required {
    font-size: 0.7rem;
    font-style: italic;
    padding-left: 5px;
}

.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5rem;
    margin-bottom: 25px;
}

.title > button {
    margin-top: 0;
}

.title > .nothing-changed {
    margin-top: 0;
}

.nothing-changed {
    margin-top: 15px;
    font-size: 1.5rem;
    color: red;
    transition: all 1s ease;
}

.labels {
    flex-grow: 1;
}