.btn {
    width: 180px;
    height: 30px;
    border: 1px solid black;
    border-radius: 29px;
    display: flex;
    justify-content: center;
    color: blue;
    align-items: center;
    background-color: white;
    transition: all 0.5s ease-in-out;
    margin-top: 15px;
    cursor: pointer;
}

.btn:hover {
    color: white;
    background-color: #0896f5;
    border: 1px solid #0896f5;
}