.container {
    display: flex;
    min-width: 640px;
    height: 60px;
    border-bottom: solid 1px black;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(99, 199, 202);
    position: sticky;
    top: 0;
    z-index: 10;
}

.pic {
    width: 40px;
    margin-right: 15px;
}

.logo{
    display: flex;
    height: 100%;
    align-items: center;
    margin-left: 25px;
}

.links {
    display: flex;
}

.user {
    margin-right: 10px;
}

.logout {
    display: flex;
}

.button {
    margin-right: 20px;
}
