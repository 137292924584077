.form {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin: 50px;
    margin-left: 0;
    padding: 20px;
    background-color: transparent;
    border: black solid 1px;
}

.title {
    font-size: 1.5rem;
    margin-bottom: 25px;
}

.required {
    font-size: 0.7rem;
    font-style: italic;
    padding-left: 5px;
}

.line-input {
    display: flex;
    flex-direction: column;
}

.name {
    margin-right: 15px;
    margin-bottom: 10px;
}

.fields {
    display: flex;
}

.input {
    margin-bottom: 10px;
    height: 1.2rem;
}