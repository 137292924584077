.container {
    margin-top: 25px;
    display: flex;
}

.labels {
    margin: 0 15px;
}

.btn {
    width: 180px;
    border: 1px solid black;
    border-radius: 29px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    transition: all 0.5s ease-in-out;
    margin-left: 15px;
}

.btn:not(:disabled) {
    cursor: pointer;
}

.btn:not(:disabled):hover {
    color: red;
    border: 1px solid red;
}
