.list-item {
    width: 150px;
    font-size: 1.2rem;
    border-radius: 20px;
    display: flex;
    margin: 0 10px;
    align-items: center;
    justify-content: center;
}

.link {
    color: inherit;
    text-decoration: none;
    text-transform: uppercase;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
}

.link::before,
.link::after {
    content: "";
    width: 100%;
    height: 2px;
    background-color: #0896f5;
    position: absolute;
    left: 0;
    transform: scaleX(0);
    transition: transform 0.5s;
}

.link::after {
    bottom:0;
    transform-origin: right;
}

.link::before {
    top:0;
    transform-origin: left;
}

.link:hover::before,
.link:hover::after {
    transform: scaleX(1);
}

@media screen and (max-width: 1365px) {
    .list-item {
        font-size: 1rem;
    }
}
