.container {
    display: flex;
    justify-content: flex-end;
    margin-right: 45px;
}

.day {
    width: 40px;
    text-align: center;
}

@media screen and (max-width: 1365px) {
    .day {
        width: 30px;
        font-size: 0.9rem;
    }
}
