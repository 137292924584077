.container {
    display: flex;
    margin-bottom: 5px;
    align-items: center;
}

.status {
    height: 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 130px;
    min-width: 130px;
    border-radius: 10px;
    margin: 0 5px;
}

.confirmed > span {
    width: 26px;
    height: 26px;
    border-radius: 0 10px 10px 0;
    text-align: center;
    cursor: default;
    background-color: darkgray;
}

.confirmed > span:hover {
    background: lightcoral;
}

.confirmed {
    background: rgb(138, 216, 122);
}

.not-confirmed {
    background: lightcoral;
}

.text {
    margin-left: 10px;
}

.big-input {
    height: 26px;
    width: 250px;
}

.small-input {
    width: 80px;
    height: 26px;
    text-align: center;
    font-size: 15px;
}

.buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.edit-button, .cancel-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 26px;
    width: 26px;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    outline: inherit;
    cursor: pointer;
}

.cancel-button {
    margin-right: 2px;
}

.edit-button:hover:enabled, .cancel-button:hover:enabled {
    background-color: darkgray;
    border-radius: 5px;
}

.edit-button:disabled, .cancel-button:disabled {
    background-color: none;
}

.remove-button {
    text-align: center;
}

.select {
    width: 85px;
    height: 26px;
    font-size: 1rem;
}

.old-button {
    height: 26px;
    margin-bottom: 15px;
}

.reminder {
    min-width: 245px;
}

@media screen and (max-width: 1365px) {
    .responsive {
        display: none;
    }

    .big-input {
        width: 130px;
    }
    
    .small-input {
        width: 50px;
    }
}
