.container {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    height: 30px;
}

.input {
    width: 220px;
    height: 25px;
    box-sizing: border-box;
}

.bad-input {
    background-color: lightcoral;
}

.options-container {
    border: 1px solid black;
    position: absolute;
    width: 220px;
    z-index: 1;
    background-color: rgb(225, 237, 250);
    max-height: 400px;
    overflow-y: auto;
}

.option {
    border: rgb(218, 218, 218) solid 0.1px;
    padding: 3px 0;
}

.option:hover {
    background-color: rgb(196, 196, 196);
}

.hover {
    background-color: rgb(196, 196, 196);
}
