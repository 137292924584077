.transparent {
    position: fixed;
    left: 0;
    top: 0;
    background-color: black;
    opacity: 0.3;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.container {
    position: fixed;
    box-sizing: border-box;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    height: 150px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    background-color: ivory;
    z-index: 101;
}

.buttons-container {
    align-self: flex-end;
}

.button {
    width: 50px;
    height: 25px;
    background-color: rgb(181, 202, 216);
    border: none;
    margin: 3px;
    border-radius: 5px;
}

.button:hover {
    background-color: rgb(167, 188, 202);
    cursor: pointer;
}
