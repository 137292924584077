.container {
    position: relative;
    display: flex;
}

.client {
    position: absolute;
    top: -20px;
}

.projects {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.project {
    display: flex;
    flex-direction: column;
}

.input-container {
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 1365px) {
    .button {
        width: 85px;
    }

    .button::before {
        content: "Add Client";
    }
}

@media screen and (max-width: 1365px) {
    .button::before {
        content: "+";
    }
}
