.form {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin-right: 50px;
    padding: 20px;
    background-color: transparent;
    border: black solid 1px;
    /* width: 300px; */
}

.fields {
    display: flex;
    flex-direction: column;
    row-gap: 6px;
}

.title {
    font-size: 1.5rem;
}

.required {
    font-size: 0.7rem;
    font-style: italic;
    padding-left: 5px;
    margin-bottom: 25px;
}

.line-input {
    display: flex;
    align-items: center;
}

.line-input > * {
    height: 2rem;
}

.name {
    flex-grow: 1;
    min-width: 130px;
    max-width: 130px;
}

.input {
    flex-grow: 2;
}