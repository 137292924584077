.container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    row-gap: 6px;
}

.grouping-container {
    display: flex;
    column-gap: 10px;
    justify-content: flex-end;
}

.grouping-dropdown {
    width: 120px;
    height: 24px;
}

.fallback-grouping {
    width: 155px;
    display: flex;
    align-items: flex-end;
    column-gap: 5px;
}

.button {
    cursor: pointer;
}