.flex {
    display: flex;
}

.container {
    position: relative;
    display: flex;
    flex-direction: column;
}

.select {
    font-size: 14px;
    font-weight: normal;
    border: 1px solid black ;
    border-radius: 3px;
    padding: 0 5px;
    height: 21px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
}

.select > span {
    font-size: 12px;
}

.dropdown {
    font-size: 14px;
    font-weight: normal;
    border: 1px solid black ;
    border-radius: 3px;
    box-sizing: border-box;
    overflow-y: auto;
    position: absolute;
    background: white;
    top: 21px;
    z-index: 1;
}

.option > div {
    margin-left: 3px;
}

.option:hover {
    background: cornflowerblue;
}

.year {
    width: 60px;
    max-height: 150px;
}

.month {
    width: 90px;
}
