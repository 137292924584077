.container {
    display: flex;
    padding: 20px;
    justify-content: space-between;
    align-items: flex-start;
}

.inner-container {
    display: flex;
}

.alert {
    background-color: lightcoral;
    border-radius: 5px;
    padding: 5px;
    margin-left: 20px;
}

@media screen and (max-width: 700px) {
    .container {
        flex-direction: column;
        align-items: center;
    }
}
