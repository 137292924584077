.form {
    display: flex;
    padding-top: 20vh;
    width: 100vw;
    align-items: center;
    flex-direction: column;
}

.logo {
    margin-bottom: 50px;
}

.message {
    background-color: rgb(236, 103, 103);
    width: 300px;
    height: 25px;
    border-radius: 10px;
    text-align: center;
    margin-top: 10px;
}

@media screen and (max-width: 1365px) {
    .form {
        padding-top: 50px;
    }

    .form > div {
        display: flex;
        flex-direction: column;
    }
}
