.container {
    display: flex;
    flex-grow: 1;
    position: relative;
    margin-bottom: 30px;
}

.project {
    position: absolute;
    top: -20px;
}

.tasks {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.input-container {
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 1365px) {
    .button {
        width: 85px;
    }

    .button::before {
        content: "Add Project";
    }
}

@media screen and (max-width: 1365px) {
    .button::before {
        content: "+";
    }
}
