.container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(239, 105, 110, 0.95);
    padding: 2rem;
    margin: 0.3rem;
    min-width: 50%;
    max-width: 70%;
    overflow-x: auto;
    border: 1px solid black;
    border-radius: 0.3rem;
    position: relative;
    z-index: 10000;
}

.btn-close {
    position: absolute;
    top: 0;
    right: 6px;
    cursor: pointer;
}