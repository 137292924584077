.container {
    width: 100%;
}

.calendar {
    display: flex;
    justify-content: space-between;
    width: 300px;
    height: 25px;
    border: 1px solid black;
    border-radius: 8px;
    padding-left: 5px;
    background: rgb(233, 233, 233);
    box-sizing: border-box;
}

.calendar > span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    background-color: darkgrey;
    border-radius: 0 8px 8px 0;
}

.calendar > span:hover {
    background-color: rgb(231, 93, 93);
    cursor: default;
}
