.container {
    margin: 0 20px 20px 20px;
}

.row {
    display: flex;
    background-color: rgb(204, 219, 247);
    border-left: 1px solid rgb(160, 160, 160);
}

.row-color {
    background-color: rgb(231, 223, 212);
}

.data {
    width: 170px;
    padding: 0 5px;
    border-right: 1px solid rgb(160, 160, 160);
    max-height: 65px;
}

.results > .data {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: break-word;
}

.date {
    width: 100px;
    min-width: 100px;
}

.hours {
    width: 50px;
    min-width: 50px;
    text-align: center;
}

.total {
    text-align: right;
}
