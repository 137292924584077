.button {
    height: 20px;
}

.holiday-picker {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
}

.holidays {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 5px;
    padding: 5px;
    border: 1px solid black;
    width: 150px;
}

.working-days {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 5px;
    padding: 5px;
    border: 1px solid black;
    width: 150px;
}

.text {
    font-size: 1.5rem;
    width: 100%;
}
