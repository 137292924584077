.list-li {
    display: flex;
    margin-top: 5px;
    height: 25px;
    margin-left: 5px;
}

.input {
    padding: 0 15px;
}

.input:focus {
    border: 1px solid #555;
}

.input-icon {
    margin-left: 10px;
    background-color: rgb(47, 163, 47);
    border-radius: 15px;
    box-sizing: border-box;
    padding: 0 15px;
    color: white;
    border: 2px solid rgb(47, 163, 47);
}

.input-icon:hover {
    transition: all 0.5s ease-in-out;
    background-color: #0896f5;
    border-color: #0896f5;
}

.red {
    background-color: rgb(240, 90, 90);
}

.btn-red {
    background-color: red;
    border-color: red;
}

.btn-red:hover {
    background-color: red;
    border-color: red;
}