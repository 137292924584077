.container {
    display: flex;
    flex-direction: column;
    margin-left: 50px;
    width: 300px;
    margin-bottom: 20px;
}

.title {
    margin-bottom: 10px;
}

.list-ul {
    list-style: none;
    margin: 0;
    padding: 0;
    height: 300px;
    border: solid 1px;
    border-radius: 5px;
    overflow-y: scroll;
    width: 100%;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.list-ul::-webkit-scrollbar {
    display: none;
}

.input:focus {
    border: 1px solid #555;
}

.btn-icon {
    margin-left: 10px;
    background-color: #f51808;
    border-radius: 15px;
    box-sizing: border-box;
    padding: 0 15px;
    color: white;
    border: 2px solid #f51808;
}


.btn-icon:hover {
    transition: all 0.5s ease-in-out;
    background-color: #fa5f06;
    border-color: #fa5f06;
}

.list-li {
    display: flex;
    margin-top: 5px;
    margin-left: 5px;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
}

.span {
    width: 185px;
}
