@import url('https://fonts.googleapis.com/css2?family=Lateef&display=swap');

.container {
    display: flex;
    font-family: 'Lateef', cursive;
    font-size: 1.3rem;
    /* width: 250px; */
    margin-right: 6px;
    padding-left: 6px;
    align-items: center;
    position: relative;
    cursor: pointer;
}

.container:hover {
    background-color: #0896f5;
    z-index: 1;
}

.container-option{
    font-size: 1.5rem;
    color: rgb(58, 50, 50);
    width: 350px;
    margin-left: 0;
}

.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 1.5rem;
    width: 1.5rem;
    right: 0rem;
  }
  
.checkmark {
    position: absolute;
    height: 1.5rem;
    width: 1.5rem;
    right: 10px;
    background-color: white;
    border-radius: 7px;
    cursor: pointer;
}

.container:hover input ~ .checkmark {
    background-color: #eee;
    box-shadow: 0 0 0 0.15rem #fff, 0 0 0.25rem 0.25rem  #0896f5;
}

.container input:checked ~ .checkmark {
    background-color: #0896f5;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: block;
}

.container input:checked ~ .checkmark:after {
    display: block;
}

.container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.box-name {
    font-family: 'Lateef', cursive;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%;
}