.status {
  height: 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100px;
  min-width: 100px;
  border-radius: 10px;
  margin: 0 5px;
  cursor: pointer;
}

.confirmed {
  background: rgb(138, 216, 122);
}

.not-confirmed {
  background: lightcoral;
}

.confirmed:hover, .not-confirmed:hover {
  background: khaki;
}

.locked {
  background: khaki;
}

.locked:hover {
  background: rgb(184, 192, 184);
}

.text {
  font-size: 0.8rem;
  margin: auto;
}
