.table > .row {
  margin-right: 6px;
  background-color: rgba(0, 0, 0, 0.1);
}

.row {
  display: flex;
  align-items: flex-start;
  border-left: 1px solid black;
}

.row:not(:first-of-type) {
  border-top: 1px solid black;
}

.row.client-row {
  width: calc(100% - 12px);
  display: flex;
  justify-content: center;
  padding: 0 5px;
  background-color: rgba(0, 0, 0, 0.2);
}

.row.table-header {
  justify-content: flex-end;   
  border: 1px solid black;
}

.row > *:not(:first-child) {
  border-left: 1px solid black;
}

.table-body {
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
}

.table-body > .row {
  border-right: 1px solid black;
}

.table-body > div:last-child > div {
  border-bottom: 0;
}

.table-body::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.table-body::-webkit-scrollbar-track {
  border-radius: 10px;
}

.table-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}

.table-body::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}

.table-body::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, 0.9);
}

.report-group {
  width: 200px;
  padding: 0 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.project {
  width: 220px;
  padding: 0 10px;
}

.hours {
  width: 80px;
  text-align: center;
}

.total-container {
  margin-right: 6px;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  background-color: rgba(0,0,0,0.1);
}

.total {
  text-align: right;
}

.project.total {
  width: unset;
  flex-grow: 1;
}