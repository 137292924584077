.transparent {
    position: fixed;
    left: 0;
    top: 0;
    background-color: black;
    opacity: 0.3;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.container {
    position: fixed;
    box-sizing: border-box;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 350px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    background-color: ivory;
    z-index: 101;
}

.select {
    width: 85px;
    height: 24px;
    font-size: 1rem;
}

.hours {
    box-sizing: border-box;
    width: 70px;
    font-size: 1rem;
}

.inner-container {
    display: flex;
}

.inner-container > div {
    width: 85px;
}

.buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}
