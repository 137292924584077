.container {
    margin: 20px;
}

.tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}

.tabs > div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    font-size: 1.2rem;
    background-color: rgb(99, 199, 202);
    width: 300px;
    border: 1px solid rgb(7, 83, 88);
    cursor: pointer;
}

.tabs > div:hover {
    background-color: rgb(115, 224, 228);
}

.tabs .selected {
    background-color: rgb(115, 224, 228);
}

.button {
    margin-bottom: 5px;
}

.headers {
    display: flex;
}

.headers > *:nth-child(1) {
    margin-left: 0.2rem;
    width: 250px;
}

.headers > *:nth-child(2) {
    width: 80px;
}

.headers > *:nth-child(3) {
    width: 250px;
}

.headers > *:nth-child(4) {
    width: 100px;
}

@media screen and (max-width: 1365px) {
    .container {
        flex-direction: column-reverse;
        margin: 10px;
    }

    .responsive {
        display: none;
    }

    .users {
        margin-top: 20px;
    }
}
