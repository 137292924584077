.container {
    display: flex;
    align-items: center;
}

.weekday {
    min-width: 110px;
    margin-left: 0.8rem;
}

.alarm {
    width: 20px;
    margin: 0 0.3rem;
    margin-right: 0.7rem;
    cursor: pointer;
}

@media screen and (max-width: 1365px) {
    .weekday {
        margin-left: 0;
    }
}
