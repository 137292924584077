.container {
    display: flex;
    margin-left: 10px;
    height: 30px;
}

.option {
    display: flex;
    justify-content: space-between;
    width: 150px;
    height: 25px;
    border: 1px solid black;
    border-radius: 8px;
    padding-left: 5px;
    margin-bottom: 5px;
    margin-right: 5px;
    background: rgb(233, 233, 233);
    box-sizing: border-box;
}

.option > div {
    width: 130px;
    overflow: hidden;
}

.option > span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    background-color: darkgrey;
    border-radius: 0 8px 8px 0;
}

.option > span:hover {
    background-color: rgb(231, 93, 93);
    cursor: default;
}
