.container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: stretch;
}

.inner-container {
    display: flex;
    justify-content: space-between;
}

.task {
    display: flex;
    flex-grow: 1;
    margin-right: 10px;
}

.task-column {
    flex-grow: 1;
}

.task-column > div,
.task-column > div > input {
    width: unset;
}

.hours {
    display: flex;
}

.buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.remove-button {
    text-align: center;
}

.checkbox-container {
    display: inline-block;
    width: 20px;
}

.checkbox {
    display: none;
}

.label {
    height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.checked {
    display: none;
}

.checkbox:checked + label > .checked {
    display: inherit;    
}

.checkbox:checked + label > .unchecked {
    display: none;    
}

.checkbox:disabled + label > .unchecked {
    display: none;
}

.label:hover {
    background: darkgray;
    border-radius: 5px;
}

.checkbox:checked + .label:hover {
    background: none;
}

.checkbox:disabled + .label:hover {
    background: none;
}

.column {
    position: absolute;
    top: -20px;
}

@media screen and (min-width: 1365px) {
    .button {
        max-width: 85px;
    }

    .button::before {
        content: "Add Task";
    }
}

@media screen and (max-width: 1365px) {
    .button {
        max-width: 40px;
    }

    .button::before {
        content: "+";
    }
}
