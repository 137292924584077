.container {
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9997;
    overflow-y: auto;
}

.inner-container {
    position: relative;
    z-index: 9998;
    min-width: 25%;
    width: 18rem;
    padding: 2% 5%;
    margin-top: 5%;
    background-color: #C3C9C5;
}

.close {
    position: absolute;
    top: 25px;
    right: 25px;
    cursor: pointer;
}

.close .bar:nth-child(1) {
    width: 25px;
    display: block;
    height: 3px;
    margin: 5px auto;
    background: #3D5344;
    transform: translateY(8px)rotate(45deg);
}

.close .bar:nth-child(2) {
    width: 25px;
    display: block;
    height: 3px;
    margin: 5px auto;
    background: #3D5344;
    transform: rotate(-45deg);
}