.container {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    width: 1092px;
}

.input-container {
    display: flex;
    flex-grow: 1;
}

.new-name-container {
    flex-grow: 1;
}

.new-name {
    box-sizing: border-box;
    width: 100%;
    height: 1.8rem;
}

.text {
    font-size: 1.5rem;
    width: 100%;
}

.select, .loading {
    height: 1.8rem;
    width: 6rem;
    font-size: 0.9rem;
}

.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-end;
    font-size: 1.1rem;
}

label {
    display: block;
}

.button {
    height: 1.8rem;
    align-self: flex-end;
}
