.container {
    display: flex;
    margin-bottom: 5px;
    align-items: center;
}

.big-input {
    width: 250px;
}

.small-input {
    width: 80px;
    height: 26px;
    text-align: center;
    font-size: 15px;
}

.return-button {
    height: 26px;
    font-size: 17px;
}

@media screen and (max-width: 1365px) {
    .responsive {
        display: none;
    }

    .big-input {
        width: 130px;
    }
}
