.container {
    position: relative;
    width: 180px;
    height: 1.8rem;
    display: flex;
    flex-direction: column;
}

.input {
    width: 180px;
    height: 100%;
    box-sizing: border-box;
}

.bad-input {
    background-color: lightcoral;
}

.options-container {
    border: 1px solid black;
    position: absolute;
    top: 1.8rem;
    width: 100%;
    z-index: 1;
    background-color: rgb(225, 237, 250);
    max-height: 400px;
    overflow-y: auto;
}

.option {
    border: rgb(218, 218, 218) solid 0.1px;
    padding: 3px 0;
    word-break: break-word;
}

.hover {
    background-color: rgb(196, 196, 196);
}

@media screen and (max-width: 1850px) {
    .container {
        width: 160px;
    }

    .input {
        width: 160px;
    }
}

@media screen and (max-width: 1680px) {
    .container {
        width: 140px;
    }

    .input {
        width: 140px;
    }
}

@media screen and (max-width: 1520px) {
    .container {
        width: 120px;
    }

    .input {
        width: 120px;
    }
}

@media screen and (max-width: 1365px) {
    .container {
        width: 250px;
    }

    .input {
        width: 250px;
    }

    .options-container {
        width: 250px;
    }
}
