@import url(https://fonts.googleapis.com/css2?family=Lateef&display=swap);
body {
    touch-action: pan-x pan-y;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.image {
    position: fixed;
    z-index: -100;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
}
.Home_form__2hHGh {
    display: flex;
    padding-top: 20vh;
    width: 100vw;
    align-items: center;
    flex-direction: column;
}

.Home_logo__1aZ6K {
    margin-bottom: 50px;
}

.Home_message__3Z2Cq {
    background-color: rgb(236, 103, 103);
    width: 300px;
    height: 25px;
    border-radius: 10px;
    text-align: center;
    margin-top: 10px;
}

@media screen and (max-width: 1365px) {
    .Home_form__2hHGh {
        padding-top: 50px;
    }

    .Home_form__2hHGh > div {
        display: flex;
        flex-direction: column;
    }
}


.AdminAdd_container__muybl {
    display: flex;
    align-items: flex-start;
}

.AdminAdd_some-fields__qzY8P {
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
}
.Button_btn__1YbxJ {
    width: 180px;
    height: 30px;
    border: 1px solid black;
    border-radius: 29px;
    display: flex;
    justify-content: center;
    color: blue;
    align-items: center;
    background-color: white;
    transition: all 0.5s ease-in-out;
    margin-top: 15px;
    cursor: pointer;
}

.Button_btn__1YbxJ:hover {
    color: white;
    background-color: #0896f5;
    border: 1px solid #0896f5;
}
.AddClient_form__3_8lF {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin: 50px;
    margin-left: 0;
    padding: 20px;
    background-color: transparent;
    border: black solid 1px;
}

.AddClient_title__29FCM {
    font-size: 1.5rem;
    margin-bottom: 25px;
}

.AddClient_required__1gXhL {
    font-size: 0.7rem;
    font-style: italic;
    padding-left: 5px;
}

.AddClient_line-input__Yhpj2 {
    display: flex;
    flex-direction: column;
}

.AddClient_name__3rUnb {
    margin-right: 15px;
    margin-bottom: 10px;
}

.AddClient_fields__36-OH {
    display: flex;
}

.AddClient_input__1JVsM {
    margin-bottom: 10px;
    height: 1.2rem;
}
.CheckBox_container__s3TyT {
    display: flex;
    font-family: 'Lateef', cursive;
    font-size: 1.3rem;
    /* width: 250px; */
    margin-right: 6px;
    padding-left: 6px;
    align-items: center;
    position: relative;
    cursor: pointer;
}

.CheckBox_container__s3TyT:hover {
    background-color: #0896f5;
    z-index: 1;
}

.CheckBox_container-option__3dNyK{
    font-size: 1.5rem;
    color: rgb(58, 50, 50);
    width: 350px;
    margin-left: 0;
}

.CheckBox_container__s3TyT input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 1.5rem;
    width: 1.5rem;
    right: 0rem;
  }
  
.CheckBox_checkmark__2Qpz2 {
    position: absolute;
    height: 1.5rem;
    width: 1.5rem;
    right: 10px;
    background-color: white;
    border-radius: 7px;
    cursor: pointer;
}

.CheckBox_container__s3TyT:hover input ~ .CheckBox_checkmark__2Qpz2 {
    background-color: #eee;
    box-shadow: 0 0 0 0.15rem #fff, 0 0 0.25rem 0.25rem  #0896f5;
}

.CheckBox_container__s3TyT input:checked ~ .CheckBox_checkmark__2Qpz2 {
    background-color: #0896f5;
}

.CheckBox_checkmark__2Qpz2:after {
    content: "";
    position: absolute;
    display: block;
}

.CheckBox_container__s3TyT input:checked ~ .CheckBox_checkmark__2Qpz2:after {
    display: block;
}

.CheckBox_container__s3TyT .CheckBox_checkmark__2Qpz2:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.CheckBox_box-name__tPBbM {
    font-family: 'Lateef', cursive;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%;
}
.CustomInput_list-li__3xHz1 {
    display: flex;
    margin-top: 5px;
    height: 25px;
    margin-left: 5px;
}

.CustomInput_input__2XSm7 {
    padding: 0 15px;
}

.CustomInput_input__2XSm7:focus {
    border: 1px solid #555;
}

.CustomInput_input-icon__1Z24l {
    margin-left: 10px;
    background-color: rgb(47, 163, 47);
    border-radius: 15px;
    box-sizing: border-box;
    padding: 0 15px;
    color: white;
    border: 2px solid rgb(47, 163, 47);
}

.CustomInput_input-icon__1Z24l:hover {
    transition: all 0.5s ease-in-out;
    background-color: #0896f5;
    border-color: #0896f5;
}

.CustomInput_red__38uGM {
    background-color: rgb(240, 90, 90);
}

.CustomInput_btn-red__13dDL {
    background-color: red;
    border-color: red;
}

.CustomInput_btn-red__13dDL:hover {
    background-color: red;
    border-color: red;
}
.AddManualProjectOption_container__VUzoI {
    display: flex;
    flex-direction: column;
    margin-left: 50px;
    width: 300px;
    margin-bottom: 20px;
}

.AddManualProjectOption_title__2HNSD {
    margin-bottom: 10px;
}

.AddManualProjectOption_list-ul__22m88 {
    list-style: none;
    margin: 0;
    padding: 0;
    height: 300px;
    border: solid 1px;
    border-radius: 5px;
    overflow-y: scroll;
    width: 100%;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.AddManualProjectOption_list-ul__22m88::-webkit-scrollbar {
    display: none;
}

.AddManualProjectOption_input__1AaD8:focus {
    border: 1px solid #555;
}

.AddManualProjectOption_btn-icon__d6VVd {
    margin-left: 10px;
    background-color: #f51808;
    border-radius: 15px;
    box-sizing: border-box;
    padding: 0 15px;
    color: white;
    border: 2px solid #f51808;
}


.AddManualProjectOption_btn-icon__d6VVd:hover {
    transition: all 0.5s ease-in-out;
    background-color: #fa5f06;
    border-color: #fa5f06;
}

.AddManualProjectOption_list-li__sxcoX {
    display: flex;
    margin-top: 5px;
    margin-left: 5px;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
}

.AddManualProjectOption_span__1jBAh {
    width: 185px;
}

.AddProjectOptions_container__tWVeG {
    display: flex;
    flex-direction: column;
}

.AddProjectOptions_filter-input__1QGxT {
    display: flex;
    margin: 20px 0 20px 50px;
}

.AddProjectOptions_lists__13RoI {
    display: flex;
    flex-direction: row;
}

.AddProjectOptions_list__1wUmm {
    display: flex;
    flex-direction: column;
    width: 300px;
    margin-left: 50px;
}

.AddProjectOptions_list-options__1G9TP {
    width: 100%;
    height: 300px;
    border: solid 1px;
    border-radius: 5px;
    overflow: hidden;
    box-sizing: border-box;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -ms-user-select: none;
    user-select: none;
}

.AddProjectOptions_list-options-selected__2bhTv {
    width: 100%;
    height: 300px;
    border: solid 1px;
    border-radius: 5px;
    overflow-y: scroll;
    box-sizing: border-box;
}

.AddProjectOptions_list-options-selected__2bhTv::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}
.AddProjectOptions_list-options-selected__2bhTv::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
}
.AddProjectOptions_list-options-selected__2bhTv::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
}
.AddProjectOptions_list-options-selected__2bhTv::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.4);
}
.AddProjectOptions_list-options-selected__2bhTv::-webkit-scrollbar-thumb:active {
    background: rgba(0, 0, 0, 0.9);
}

.AddProjectOptions_input__2kxii {
    margin: 0 20px;
    padding-left: 20px;
    width: 50px;
    transition: 0.4s ease-in-out;
    height: 1.2rem;
}

.AddProjectOptions_input__2kxii:focus {
    width: 50%;
}

.AddProjectOptions_list-label__1eNgL {
    margin-bottom: 10px;
}

.AddProjectOptions_filter__10Zfe {
    font-style: italic;
}

.AddProjectOptions_checkbox-list__1Tvoo {
    width: 295px !important;
    overflow-x: hidden !important;
    scrollbar-width: thin;
}

.AddProjectOptions_checkbox-list__1Tvoo::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}
.AddProjectOptions_checkbox-list__1Tvoo::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
}
.AddProjectOptions_checkbox-list__1Tvoo::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
}
.AddProjectOptions_checkbox-list__1Tvoo::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.4);
}
.AddProjectOptions_checkbox-list__1Tvoo::-webkit-scrollbar-thumb:active {
    background: rgba(0, 0, 0, 0.9);
}

.ConfirmationDialogBox_transparent__yQt3a {
    position: fixed;
    left: 0;
    top: 0;
    background-color: black;
    opacity: 0.3;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.ConfirmationDialogBox_container__1mbUp {
    position: fixed;
    box-sizing: border-box;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 400px;
    height: 150px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    background-color: ivory;
    z-index: 101;
}

.ConfirmationDialogBox_buttons-container__35YgP {
    align-self: flex-end;
}

.ConfirmationDialogBox_button__1mUCC {
    width: 50px;
    height: 25px;
    background-color: rgb(181, 202, 216);
    border: none;
    margin: 3px;
    border-radius: 5px;
}

.ConfirmationDialogBox_button__1mUCC:hover {
    background-color: rgb(167, 188, 202);
    cursor: pointer;
}

.ProjectOptions_option-container__2vFDf {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.ProjectOptions_data-fetching__3AXBI {
    text-align: center;
    margin: 2rem 0;
    font-size: 2.5rem;
}
.InputDropdown_container__2uctP {
    position: relative;
    width: 180px;
    height: 1.8rem;
    display: flex;
    flex-direction: column;
}

.InputDropdown_input__1Bh9I {
    width: 180px;
    height: 100%;
    box-sizing: border-box;
}

.InputDropdown_bad-input__1Egpv {
    background-color: lightcoral;
}

.InputDropdown_options-container__2LyH0 {
    border: 1px solid black;
    position: absolute;
    top: 1.8rem;
    width: 100%;
    z-index: 1;
    background-color: rgb(225, 237, 250);
    max-height: 400px;
    overflow-y: auto;
}

.InputDropdown_option__YdOyl {
    border: rgb(218, 218, 218) solid 0.1px;
    padding: 3px 0;
    word-break: break-word;
}

.InputDropdown_hover__3yU09 {
    background-color: rgb(196, 196, 196);
}

@media screen and (max-width: 1850px) {
    .InputDropdown_container__2uctP {
        width: 160px;
    }

    .InputDropdown_input__1Bh9I {
        width: 160px;
    }
}

@media screen and (max-width: 1680px) {
    .InputDropdown_container__2uctP {
        width: 140px;
    }

    .InputDropdown_input__1Bh9I {
        width: 140px;
    }
}

@media screen and (max-width: 1520px) {
    .InputDropdown_container__2uctP {
        width: 120px;
    }

    .InputDropdown_input__1Bh9I {
        width: 120px;
    }
}

@media screen and (max-width: 1365px) {
    .InputDropdown_container__2uctP {
        width: 250px;
    }

    .InputDropdown_input__1Bh9I {
        width: 250px;
    }

    .InputDropdown_options-container__2LyH0 {
        width: 250px;
    }
}

.WindowContainer_container__3QNhA {
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9997;
    overflow-y: auto;
}

.WindowContainer_inner-container__1zHDd {
    position: relative;
    z-index: 9998;
    min-width: 25%;
    width: 18rem;
    padding: 2% 5%;
    margin-top: 5%;
    background-color: #C3C9C5;
}

.WindowContainer_close__Ns7Yy {
    position: absolute;
    top: 25px;
    right: 25px;
    cursor: pointer;
}

.WindowContainer_close__Ns7Yy .WindowContainer_bar__RrMgG:nth-child(1) {
    width: 25px;
    display: block;
    height: 3px;
    margin: 5px auto;
    background: #3D5344;
    -webkit-transform: translateY(8px)rotate(45deg);
            transform: translateY(8px)rotate(45deg);
}

.WindowContainer_close__Ns7Yy .WindowContainer_bar__RrMgG:nth-child(2) {
    width: 25px;
    display: block;
    height: 3px;
    margin: 5px auto;
    background: #3D5344;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}
.CopyProject_container__27cgs {
    margin-top: 25px;
    display: flex;
}

.CopyProject_labels__2nyAu {
    margin: 0 15px;
}

.CopyProject_btn__1dunl {
    width: 180px;
    border: 1px solid black;
    border-radius: 29px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    transition: all 0.5s ease-in-out;
    margin-left: 15px;
}

.CopyProject_btn__1dunl:not(:disabled) {
    cursor: pointer;
}

.CopyProject_btn__1dunl:not(:disabled):hover {
    color: red;
    border: 1px solid red;
}

.AddProject_form__32Dk2 {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin: 50px;
    padding: 20px;
    width: 1092px;
    border: black solid 1px;
}

.AddProject_title__1H9RF {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5rem;
    margin-bottom: 25px;
}

.AddProject_one-line-field__NM8DP {
    display: flex;
    align-items: center;
    height: 2.8rem;
    margin-right: 15px;
}

.AddProject_one-line-field__NM8DP > select {
    height: 1.5rem;
    width: 300px;
}

.AddProject_input__2bW8d {
    height: 1.5rem;
    flex-grow: 1;
}

.AddProject_name-field__2s9cP {
    font-size: 1rem;
    margin-right: 15px;
    width: 200px;
}

.AddProject_required__1ipZA {
    font-size: 0.7rem;
    font-style: italic;
    padding-left: 5px;
}

/* .option-container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
} */

.AddProject_new-container__1R45K {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    width: 100%;
    margin-bottom: 25px;
}

.AddProject_client-text__Cj13B {
    margin-right: 15px;
}

.AddProject_error__2VQbh {
    margin-top: 5px;
    color: red;
}
.AddUser_form__2ZS2U {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin-right: 50px;
    padding: 20px;
    background-color: transparent;
    border: black solid 1px;
    /* width: 300px; */
}

.AddUser_fields__27n6g {
    display: flex;
    flex-direction: column;
    grid-row-gap: 6px;
    row-gap: 6px;
}

.AddUser_title__2aYcD {
    font-size: 1.5rem;
}

.AddUser_required__2_aI_ {
    font-size: 0.7rem;
    font-style: italic;
    padding-left: 5px;
    margin-bottom: 25px;
}

.AddUser_line-input__1uui0 {
    display: flex;
    align-items: center;
}

.AddUser_line-input__1uui0 > * {
    height: 2rem;
}

.AddUser_name__3QuZS {
    flex-grow: 1;
    min-width: 130px;
    max-width: 130px;
}

.AddUser_input__2bXSS {
    flex-grow: 2;
}
.NavigationLink_list-item__3McqP {
    width: 150px;
    font-size: 1.2rem;
    border-radius: 20px;
    display: flex;
    margin: 0 10px;
    align-items: center;
    justify-content: center;
}

.NavigationLink_link__3H5u8 {
    color: inherit;
    text-decoration: none;
    text-transform: uppercase;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
}

.NavigationLink_link__3H5u8::before,
.NavigationLink_link__3H5u8::after {
    content: "";
    width: 100%;
    height: 2px;
    background-color: #0896f5;
    position: absolute;
    left: 0;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
    transition: transform 0.5s, -webkit-transform 0.5s;
}

.NavigationLink_link__3H5u8::after {
    bottom:0;
    -webkit-transform-origin: right;
            transform-origin: right;
}

.NavigationLink_link__3H5u8::before {
    top:0;
    -webkit-transform-origin: left;
            transform-origin: left;
}

.NavigationLink_link__3H5u8:hover::before,
.NavigationLink_link__3H5u8:hover::after {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
}

@media screen and (max-width: 1365px) {
    .NavigationLink_list-item__3McqP {
        font-size: 1rem;
    }
}

.NavigationBar_container__2owTZ {
    display: flex;
    min-width: 640px;
    height: 60px;
    border-bottom: solid 1px black;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(99, 199, 202);
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 10;
}

.NavigationBar_pic__2NuPH {
    width: 40px;
    margin-right: 15px;
}

.NavigationBar_logo__3dGmY{
    display: flex;
    height: 100%;
    align-items: center;
    margin-left: 25px;
}

.NavigationBar_links__2hmp0 {
    display: flex;
}

.NavigationBar_user__8Cke1 {
    margin-right: 10px;
}

.NavigationBar_logout__1Dv_K {
    display: flex;
}

.NavigationBar_button__2tBN8 {
    margin-right: 20px;
}

.EditProject_container__1IChK {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 1092px;
    border: black solid 1px;
}

.EditProject_container__1IChK > h3 {
    font-weight: 500;
}

.EditProject_filters__3RBIL {
    border: 1px dotted;
    padding: 20px;
    margin-bottom: 40px;
    background-color: rgba(0, 0, 0, 0.1);
}

.EditProject_filters__3RBIL > h3 {
    margin-top: 15px;
}

.EditProject_filters-heading__1lyMY {
    font-weight: 500;
}

.EditProject_select-fields__1FkSf {
    display: flex;
    margin-bottom: 15px;
}

.EditProject_select-fields__1FkSf > label:not(:first-of-type) {
    margin-left: 16px;
}

.EditProject_select__3I6As {
    margin: 0 15px;
    width: 300px;
    height: 1.5rem;
}

.EditProject_info__3MT9l {
    display: flex;
    flex-direction: column;
    grid-row-gap: 5px;
    row-gap: 5px;
}

.EditProject_one-line-field__2RvIp {
    display: flex;
    align-items: center;
}

.EditProject_one-line-field__2RvIp > input {
    flex-grow: 1;
    height: 1.5rem;
}

.EditProject_name-field__1nF1_ {
    font-size: 1rem;
    margin-right: 15px;
    width: 200px;
}

.EditProject_required__1cFrV {
    font-size: 0.7rem;
    font-style: italic;
    padding-left: 5px;
}

.EditProject_title__1iQYa {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5rem;
    margin-bottom: 25px;
}

.EditProject_title__1iQYa > button {
    margin-top: 0;
}

.EditProject_title__1iQYa > .EditProject_nothing-changed__TgXaG {
    margin-top: 0;
}

.EditProject_nothing-changed__TgXaG {
    margin-top: 15px;
    font-size: 1.5rem;
    color: red;
    transition: all 1s ease;
}

.EditProject_labels___x6Bl {
    flex-grow: 1;
}
.AdminEditPage_container__2TDT0 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 50px;
}
.AddHolidays_button__3aUHD {
    height: 20px;
}

.AddHolidays_holiday-picker__3DAPT {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
}

.AddHolidays_holidays__zgRg0 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 5px;
    padding: 5px;
    border: 1px solid black;
    width: 150px;
}

.AddHolidays_working-days__sHx-1 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 5px;
    padding: 5px;
    border: 1px solid black;
    width: 150px;
}

.AddHolidays_text__3N6ly {
    font-size: 1.5rem;
    width: 100%;
}

.EditNames_container__4ddwZ {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    width: 1092px;
}

.EditNames_input-container__3f_gC {
    display: flex;
    flex-grow: 1;
}

.EditNames_new-name-container__rIeDA {
    flex-grow: 1;
}

.EditNames_new-name__1Dg0l {
    box-sizing: border-box;
    width: 100%;
    height: 1.8rem;
}

.EditNames_text__WMyVf {
    font-size: 1.5rem;
    width: 100%;
}

.EditNames_select__3vYkW, .EditNames_loading__2aOtV {
    height: 1.8rem;
    width: 6rem;
    font-size: 0.9rem;
}

.EditNames_loading__2aOtV {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-end;
    font-size: 1.1rem;
}

label {
    display: block;
}

.EditNames_button__2lMyO {
    height: 1.8rem;
    align-self: flex-end;
}

.StatisticsCalendarSelection_container__2guZk {
    width: 100%;
}

.StatisticsCalendarSelection_calendar__22atj {
    display: flex;
    justify-content: space-between;
    width: 300px;
    height: 25px;
    border: 1px solid black;
    border-radius: 8px;
    padding-left: 5px;
    background: rgb(233, 233, 233);
    box-sizing: border-box;
}

.StatisticsCalendarSelection_calendar__22atj > span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    background-color: darkgrey;
    border-radius: 0 8px 8px 0;
}

.StatisticsCalendarSelection_calendar__22atj > span:hover {
    background-color: rgb(231, 93, 93);
    cursor: default;
}

.YearMonthForm_flex__1NNgH {
    display: flex;
}

.YearMonthForm_container__UFfEZ {
    position: relative;
    display: flex;
    flex-direction: column;
}

.YearMonthForm_select__1xqan {
    font-size: 14px;
    font-weight: normal;
    border: 1px solid black ;
    border-radius: 3px;
    padding: 0 5px;
    height: 21px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
}

.YearMonthForm_select__1xqan > span {
    font-size: 12px;
}

.YearMonthForm_dropdown__31YCX {
    font-size: 14px;
    font-weight: normal;
    border: 1px solid black ;
    border-radius: 3px;
    box-sizing: border-box;
    overflow-y: auto;
    position: absolute;
    background: white;
    top: 21px;
    z-index: 1;
}

.YearMonthForm_option__31V-3 > div {
    margin-left: 3px;
}

.YearMonthForm_option__31V-3:hover {
    background: cornflowerblue;
}

.YearMonthForm_year__tk0I6 {
    width: 60px;
    max-height: 150px;
}

.YearMonthForm_month__3tTn2 {
    width: 90px;
}

.InputStatistics_container__owEHT {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    height: 30px;
}

.InputStatistics_input__5vk1q {
    width: 220px;
    height: 25px;
    box-sizing: border-box;
}

.InputStatistics_bad-input__2Wmkz {
    background-color: lightcoral;
}

.InputStatistics_options-container__3qTCu {
    border: 1px solid black;
    position: absolute;
    width: 220px;
    z-index: 1;
    background-color: rgb(225, 237, 250);
    max-height: 400px;
    overflow-y: auto;
}

.InputStatistics_option__3HZTt {
    border: rgb(218, 218, 218) solid 0.1px;
    padding: 3px 0;
}

.InputStatistics_option__3HZTt:hover {
    background-color: rgb(196, 196, 196);
}

.InputStatistics_hover__TdxVF {
    background-color: rgb(196, 196, 196);
}

.StatisticsFilters_button__170Ob {
    margin-left: 20px;
}

.StatisticsFilters_alert__1jfra {
    background-color: lightcoral;
    border-radius: 5px;
    padding: 5px;
    margin-left: 20px;
    margin-top: 5px;
    position: absolute;
}

.StatisticsFilters_user__3rvnm {
    display: flex;
}

.StatisticsFilters_user__3rvnm > input {
    height: 20px;
}

@media screen and (max-width: 1365px) {
    .StatisticsFilters_container__3xi8a {
        display: none;
    }
}

.StatisticsQuickReport_container__3XCaU {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    grid-row-gap: 6px;
    row-gap: 6px;
}

.StatisticsQuickReport_grouping-container__2aVos {
    display: flex;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
    justify-content: flex-end;
}

.StatisticsQuickReport_grouping-dropdown__2jchh {
    width: 120px;
    height: 24px;
}

.StatisticsQuickReport_fallback-grouping__2XUci {
    width: 155px;
    display: flex;
    align-items: flex-end;
    grid-column-gap: 5px;
    -webkit-column-gap: 5px;
            column-gap: 5px;
}

.StatisticsQuickReport_button__1FNr4 {
    cursor: pointer;
}
.ExportReport_btn-download__3N7tA {
    width: 0;
    height: 0;
}
.Report_button__16xLb:hover {
    background-color: rgb(198, 218, 255);
    cursor: pointer;
}
.QuickReportsTable_table__1XzWm > .QuickReportsTable_row__2iL-V {
  margin-right: 6px;
  background-color: rgba(0, 0, 0, 0.1);
}

.QuickReportsTable_row__2iL-V {
  display: flex;
  align-items: flex-start;
  border-left: 1px solid black;
}

.QuickReportsTable_row__2iL-V:not(:first-of-type) {
  border-top: 1px solid black;
}

.QuickReportsTable_row__2iL-V.QuickReportsTable_client-row__3NlOS {
  width: calc(100% - 12px);
  display: flex;
  justify-content: center;
  padding: 0 5px;
  background-color: rgba(0, 0, 0, 0.2);
}

.QuickReportsTable_row__2iL-V.QuickReportsTable_table-header__1IX2Q {
  justify-content: flex-end;   
  border: 1px solid black;
}

.QuickReportsTable_row__2iL-V > *:not(:first-child) {
  border-left: 1px solid black;
}

.QuickReportsTable_table-body__3SLtG {
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
}

.QuickReportsTable_table-body__3SLtG > .QuickReportsTable_row__2iL-V {
  border-right: 1px solid black;
}

.QuickReportsTable_table-body__3SLtG > div:last-child > div {
  border-bottom: 0;
}

.QuickReportsTable_table-body__3SLtG::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.QuickReportsTable_table-body__3SLtG::-webkit-scrollbar-track {
  border-radius: 10px;
}

.QuickReportsTable_table-body__3SLtG::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}

.QuickReportsTable_table-body__3SLtG::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}

.QuickReportsTable_table-body__3SLtG::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, 0.9);
}

.QuickReportsTable_report-group__2-vQJ {
  width: 200px;
  padding: 0 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.QuickReportsTable_project__dwzz3 {
  width: 220px;
  padding: 0 10px;
}

.QuickReportsTable_hours__2FkMl {
  width: 80px;
  text-align: center;
}

.QuickReportsTable_total-container__l_55u {
  margin-right: 6px;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  background-color: rgba(0,0,0,0.1);
}

.QuickReportsTable_total__2y--e {
  text-align: right;
}

.QuickReportsTable_project__dwzz3.QuickReportsTable_total__2y--e {
  width: unset;
  flex-grow: 1;
}
.Pagination_container__2ZyPr {
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
}

.Pagination_text__2kVio {
    margin: 0 10px;
}

.StatisticsResultsHeader_container__3O_46 {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}
.StatisticsResults_container__1VscL {
    margin: 0 20px 20px 20px;
}

.StatisticsResults_row__132hS {
    display: flex;
    background-color: rgb(204, 219, 247);
    border-left: 1px solid rgb(160, 160, 160);
}

.StatisticsResults_row-color__12frp {
    background-color: rgb(231, 223, 212);
}

.StatisticsResults_data__8-lf0 {
    width: 170px;
    padding: 0 5px;
    border-right: 1px solid rgb(160, 160, 160);
    max-height: 65px;
}

.StatisticsResults_results__3KXXK > .StatisticsResults_data__8-lf0 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: break-word;
}

.StatisticsResults_date__3BwtG {
    width: 100px;
    min-width: 100px;
}

.StatisticsResults_hours__2zOvu {
    width: 50px;
    min-width: 50px;
    text-align: center;
}

.StatisticsResults_total__jSu32 {
    text-align: right;
}

.StatisticsSelectionByType_container__2n4p9 {
    display: flex;
    margin-left: 10px;
    height: 30px;
}

.StatisticsSelectionByType_option__OHY6W {
    display: flex;
    justify-content: space-between;
    width: 150px;
    height: 25px;
    border: 1px solid black;
    border-radius: 8px;
    padding-left: 5px;
    margin-bottom: 5px;
    margin-right: 5px;
    background: rgb(233, 233, 233);
    box-sizing: border-box;
}

.StatisticsSelectionByType_option__OHY6W > div {
    width: 130px;
    overflow: hidden;
}

.StatisticsSelectionByType_option__OHY6W > span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    background-color: darkgrey;
    border-radius: 0 8px 8px 0;
}

.StatisticsSelectionByType_option__OHY6W > span:hover {
    background-color: rgb(231, 93, 93);
    cursor: default;
}

.Statistics_container___NyhZ {
    display: flex;
    padding: 20px;
    justify-content: space-between;
    align-items: flex-start;
}

.Statistics_inner-container__3PcCC {
    display: flex;
}

.Statistics_alert__30_sc {
    background-color: lightcoral;
    border-radius: 5px;
    padding: 5px;
    margin-left: 20px;
}

@media screen and (max-width: 700px) {
    .Statistics_container___NyhZ {
        flex-direction: column;
        align-items: center;
    }
}

.TimeCard_container__1hUj- {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    box-sizing: border-box;
    min-width: 640px;
}

.TimeCard_tasks__1w6aO {
    display: flex;
    align-items: flex-start;
    width: 100%;
}

.TimeCard_inner-container__3Bmb2 {
    width: 100%;
}

.TimeCard_new-tasks__1eYX5 {
    width: 100%;
    border: 1px solid black;
    border-radius: 15px;
    padding: 10px;
    margin-top: 20px;
}

.TimeCard_client__RjR7w {
    display: flex;
    flex-direction: column;
}

.TimeCard_button__1bslg {
    display: flex;
    justify-content: flex-end;
}

.TimeCard_warnings__c87E8 {
    background-color: lightcoral;
    width: 400px;
    border-radius: 5px;
    text-align: center;
}

@media screen and (max-width: 1365px) {
    .TimeCard_tasks__1w6aO {
        flex-direction: column;
        align-items: center;
    }
}

.DayPicker {
    font-size: 1rem;
    background: white;
    border-radius: 20px;
    border: 1px solid black;
    display: inline-block;
}

.DayPicker-TodayButton {
    width: 100%;
}

.DayPicker-Day {
    border-radius: 0;
}

.DayPicker-Month {
    border-collapse: separate;
}

.DayPicker-WeekNumber {
    outline: none;
}

.DayPicker-Day--hoverRange {
    background-color: #EFEFEF !important;
}

.DayPicker-Day--selectedRange {
    background-color: #fff7ba !important;
    border-top-color: #FFEB3B;
    border-bottom-color: #FFEB3B;
    border-left-color: #fff7ba;
    border-right-color: #fff7ba;
}

.DayPicker-Day--selectedRangeStart {
    border-left: 1px solid #FFEB3B;
}

.DayPicker-Day--selectedRangeEnd {
    border-right: 1px solid #FFEB3B;
}

.DayPicker-Day--selectedRange:not(.DayPicker-Day--outside).DayPicker-Day--selected, .DayPicker-Day--hoverRange:not(.DayPicker-Day--outside).DayPicker-Day--selected {
    color: black;
}

.DayPicker-Day--weekends:not(.DayPicker-Day--workingDays) {
    background-color: rgb(226, 226, 226);
    color: rgb(202, 0, 0) !important;
}

.DayPicker-Day--holidays:not(.DayPicker-Day--workingDays) {
    background-color: rgb(226, 226, 226);
    color: rgb(202, 0, 0) !important;
}

.Client_container__3HaKz {
    position: relative;
    display: flex;
}

.Client_client__PVoGa {
    position: absolute;
    top: -20px;
}

.Client_projects__2Fska {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.Client_project__2dIlk {
    display: flex;
    flex-direction: column;
}

.Client_input-container__17E_L {
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 1365px) {
    .Client_button__32wAo {
        width: 85px;
    }

    .Client_button__32wAo::before {
        content: "Add Client";
    }
}

@media screen and (max-width: 1365px) {
    .Client_button__32wAo::before {
        content: "+";
    }
}

.Project_container__3yp3J {
    display: flex;
    flex-grow: 1;
    position: relative;
    margin-bottom: 30px;
}

.Project_project__orSB7 {
    position: absolute;
    top: -20px;
}

.Project_tasks__1n8jS {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.Project_input-container__11DZF {
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 1365px) {
    .Project_button__4klpi {
        width: 85px;
    }

    .Project_button__4klpi::before {
        content: "Add Project";
    }
}

@media screen and (max-width: 1365px) {
    .Project_button__4klpi::before {
        content: "+";
    }
}

.Task_container__3G81F {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: stretch;
}

.Task_inner-container__i4GRd {
    display: flex;
    justify-content: space-between;
}

.Task_task__2g0FK {
    display: flex;
    flex-grow: 1;
    margin-right: 10px;
}

.Task_task-column__1O30_ {
    flex-grow: 1;
}

.Task_task-column__1O30_ > div,
.Task_task-column__1O30_ > div > input {
    width: unset;
}

.Task_hours__1T1bK {
    display: flex;
}

.Task_buttons-container__-JFfs {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Task_remove-button__11Qag {
    text-align: center;
}

.Task_checkbox-container__8k0rS {
    display: inline-block;
    width: 20px;
}

.Task_checkbox__1r1sr {
    display: none;
}

.Task_label__fIvRy {
    height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Task_checked__21up7 {
    display: none;
}

.Task_checkbox__1r1sr:checked + label > .Task_checked__21up7 {
    display: inherit;    
}

.Task_checkbox__1r1sr:checked + label > .Task_unchecked__2CyYm {
    display: none;    
}

.Task_checkbox__1r1sr:disabled + label > .Task_unchecked__2CyYm {
    display: none;
}

.Task_label__fIvRy:hover {
    background: darkgray;
    border-radius: 5px;
}

.Task_checkbox__1r1sr:checked + .Task_label__fIvRy:hover {
    background: none;
}

.Task_checkbox__1r1sr:disabled + .Task_label__fIvRy:hover {
    background: none;
}

.Task_column__1f5N6 {
    position: absolute;
    top: -20px;
}

@media screen and (min-width: 1365px) {
    .Task_button__u65w2 {
        max-width: 85px;
    }

    .Task_button__u65w2::before {
        content: "Add Task";
    }
}

@media screen and (max-width: 1365px) {
    .Task_button__u65w2 {
        max-width: 40px;
    }

    .Task_button__u65w2::before {
        content: "+";
    }
}

.HoursInput_input__1-SfY {
    box-sizing: border-box;
    width: 40px;
    text-align: center;
}

/* Chrome, Safari, Edge, Opera */
.HoursInput_input__1-SfY::-webkit-outer-spin-button, .HoursInput_input__1-SfY::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.HoursInput_input__1-SfY[type=number] {
    -moz-appearance: textfield;
}

.HoursInput_input__1-SfY:invalid {
    background-color: lightcoral;
}

.HoursInput_updated__1PbfG {
    background-color: yellow;
}

@media screen and (max-width: 1365px) {
    .HoursInput_input__1-SfY {
        width: 30px;
    }
}

.ResponsiveInput_title__2VQ3b {
    position: absolute;
    top: -20px;
}

.ResponsiveInput_input__bObE- {
    box-sizing: border-box;
    width: 40px;
    height: 20px;
}

.ResponsiveInput_mobile__1H2ww {
    position: relative;
}

.ResponsiveInput_transparent__2fkbF {
    position: fixed;
    left: 0;
    top: 0;
    background-color: black;
    opacity: 0.3;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.ResponsiveInput_container__23aII {
    position: fixed;
    box-sizing: border-box;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 300px;
    height: 100px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    padding: 1rem;
    background-color: ivory;
    z-index: 101;
}

.ResponsiveInput_bad-input__3mddj {
    background-color: lightcoral;
}

.Weekdays_container__21lLI {
    display: flex;
    justify-content: flex-end;
    margin-right: 45px;
}

.Weekdays_day__68nWn {
    width: 40px;
    text-align: center;
}

@media screen and (max-width: 1365px) {
    .Weekdays_day__68nWn {
        width: 30px;
        font-size: 0.9rem;
    }
}

.SavedTask_container__2t9gy {
    display: flex;
    flex-direction: column;
}

.SavedTask_column-names__1DhaJ {
    display: flex;
    margin-left: 10px;
    max-width: calc(100% - 343px);
}

.SavedTask_task__3JZ7U {
    display: flex;
    margin-left: 10px;
}

.SavedTask_column__2dz4z {
    width: 180px;
    height: 21px;
    flex-grow: 1;
    overflow: hidden;
    box-sizing: border-box;
}

.SavedTask_left__1mLNG {
    display: flex;    
    flex-grow: 1;
    margin-right: 10px;
}

.SavedTask_right__27EA_ {
    display: flex;
}

.SavedTask_buttons-container__2Bncw {
    display: flex;
    justify-content: center;
    align-items: center;
}

.SavedTask_edit-button__12wer, .SavedTask_cancel-button__LQ7a1 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 21px;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    outline: inherit;
}

.SavedTask_edit-button__12wer {
    width: 20px;
}

.SavedTask_edit-button__12wer:hover:enabled, .SavedTask_cancel-button__LQ7a1:hover:enabled {
    background-color: darkgray;
    border-radius: 5px;
}

.SavedTask_edit-button__12wer:disabled, .SavedTask_cancel-button__LQ7a1:disabled {
    background-color: none;
}

.SavedTask_remove-button__1zubf {
    text-align: center;
}

.SavedTask_mobile__9tv3q {
    display: none;
}

.SavedTask_transparent__3qTDG {
    position: fixed;
    left: 0;
    top: 0;
    background-color: black;
    opacity: 0.3;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.SavedTask_description-container__2msrP {
    position: fixed;
    box-sizing: border-box;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 450px;
    max-height: 80%;
    overflow-y: scroll;
    border-radius: 10px;
    padding: 1rem;
    background-color: ivory;
    z-index: 101;
}

@media screen and (max-width: 1850px) {
    .SavedTask_column__2dz4z {
        width: 155px;
    }
}

@media screen and (max-width: 1680px) {
    .SavedTask_column__2dz4z {
        width: 130px;
    }
}

@media screen and (max-width: 1520px) {
    .SavedTask_column__2dz4z {
        width: 104px;
    }
}

@media screen and (max-width: 1365px) {
    .SavedTask_column__2dz4z {
        width: 40px;
    }

    .SavedTask_desktop__23LNB {
        display: none;
    }

    .SavedTask_mobile__9tv3q {
        display: inline;
        display: initial;
    }
}

.DescriptionTextarea_textarea__bBA0a {
    width: 90%;
    resize: none;
}
.TotalHours_container__1f-OT {
    display: flex;
    justify-content: flex-end;
    margin-right: 56px;
}

.TotalHours_text__1HoDn {
    margin-right: 10px;
}

.Overtime_container__3yFh5 {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-right: 11px;
}

.Overtime_text__2aav2 {
    margin-right: 10px;
}

.Overtime_buttons-container__1mUmp {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Overtime_edit-button__2SlWw, .Overtime_cancel-button__2V6hu {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 21px;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    outline: inherit;
}

.Overtime_edit-button__2SlWw {
    width: 20px;
}

.Overtime_edit-button__2SlWw:hover:enabled, .Overtime_cancel-button__2V6hu:hover:enabled {
    background-color: darkgray;
    border-radius: 5px;
}

.Overtime_edit-button__2SlWw:disabled, .Overtime_cancel-button__2V6hu:disabled {
    background-color: none;
}

.Overtime_remove-button__3CCIv {
    text-align: center;
    width: 25px;
}

.Overtime_placeholder__E2eB1 {
    margin-right: 25px;
}

.Overtime_confirm__3ffvo {
    display: flex;
    justify-content: flex-end;
    margin-right: 45px;
    width: 100%;
}

.Overtime_confirm__3ffvo > button {
    width: 130px;
}

.Overtime_confirm__3ffvo > .Overtime_remove-confirmation__1zMfP {
    width: 30px;
}

.SavedTimeCard_container__2rSuR {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.SavedTimeCard_inner-container__2eQuD {
    position: relative;
    border: 1px solid black;
    border-radius: 15px;
    padding: 10px;
    margin-bottom: 10px;
    margin-left: 45px;
}

.SavedTimeCard_project___O7lK {
    margin-bottom: 20px;
}

.SavedTimeCard_username__1g3Ax {
    text-align: right;
}

.SavedTimeCard_weekdays__Ch0K3 {
    position: absolute;
    width: calc(100% - 20px);
    margin-top: 21px;
}

@media screen and (max-width: 1365px) {
    .SavedTimeCard_inner-container__2eQuD {
        margin-left: 0;
        margin-top: 20px;
    }
}

.UserReference_container__1mWOo {
    margin-top: 20px;
}

.range .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
}

.range .DayPicker-Day {
    border-radius: 0 !important;
}

.AlertDialogBox_transparent__1CNDn {
    position: fixed;
    left: 0;
    top: 0;
    background-color: black;
    opacity: 0.3;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.AlertDialogBox_container__RtbMm {
    position: fixed;
    box-sizing: border-box;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 400px;
    height: 150px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    background-color: ivory;
    z-index: 101;
}

.AlertDialogBox_buttons-container__20qzK {
    align-self: flex-end;
}

.AlertDialogBox_button__2tJyG {
    width: 50px;
    height: 25px;
    background-color: rgb(181, 202, 216);
    border: none;
    margin: 3px;
    border-radius: 5px;
}

.AlertDialogBox_button__2tJyG:hover {
    background-color: rgb(167, 188, 202);
    cursor: pointer;
}

.InputUser_input__2W9sO {
    font-size: 15px;
    height: 26px;
    box-sizing: border-box;
}
.UserReminder_container__22nUH {
    display: flex;
    align-items: center;
}

.UserReminder_weekday__1zqiW {
    min-width: 110px;
    margin-left: 0.8rem;
}

.UserReminder_alarm__1VqYm {
    width: 20px;
    margin: 0 0.3rem;
    margin-right: 0.7rem;
    cursor: pointer;
}

@media screen and (max-width: 1365px) {
    .UserReminder_weekday__1zqiW {
        margin-left: 0;
    }
}

.User_container__560S0 {
    display: flex;
    margin-bottom: 5px;
    align-items: center;
}

.User_status__3wTGo {
    height: 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 130px;
    min-width: 130px;
    border-radius: 10px;
    margin: 0 5px;
}

.User_confirmed__22z-S > span {
    width: 26px;
    height: 26px;
    border-radius: 0 10px 10px 0;
    text-align: center;
    cursor: default;
    background-color: darkgray;
}

.User_confirmed__22z-S > span:hover {
    background: lightcoral;
}

.User_confirmed__22z-S {
    background: rgb(138, 216, 122);
}

.User_not-confirmed__1tyGI {
    background: lightcoral;
}

.User_text__2HxEy {
    margin-left: 10px;
}

.User_big-input__RL_4l {
    height: 26px;
    width: 250px;
}

.User_small-input__tU35K {
    width: 80px;
    height: 26px;
    text-align: center;
    font-size: 15px;
}

.User_buttons-container__2ojBf {
    display: flex;
    justify-content: center;
    align-items: center;
}

.User_edit-button__1mXTP, .User_cancel-button__25b_1 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 26px;
    width: 26px;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    outline: inherit;
    cursor: pointer;
}

.User_cancel-button__25b_1 {
    margin-right: 2px;
}

.User_edit-button__1mXTP:hover:enabled, .User_cancel-button__25b_1:hover:enabled {
    background-color: darkgray;
    border-radius: 5px;
}

.User_edit-button__1mXTP:disabled, .User_cancel-button__25b_1:disabled {
    background-color: none;
}

.User_remove-button__1yoSI {
    text-align: center;
}

.User_select__2Mkhj {
    width: 85px;
    height: 26px;
    font-size: 1rem;
}

.User_old-button__1vUsB {
    height: 26px;
    margin-bottom: 15px;
}

.User_reminder__2xzFH {
    min-width: 245px;
}

@media screen and (max-width: 1365px) {
    .User_responsive__7X6tB {
        display: none;
    }

    .User_big-input__RL_4l {
        width: 130px;
    }
    
    .User_small-input__tU35K {
        width: 50px;
    }
}

.UserMobileEditMenu_transparent__3x1IH {
    position: fixed;
    left: 0;
    top: 0;
    background-color: black;
    opacity: 0.3;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.UserMobileEditMenu_container__2zQv5 {
    position: fixed;
    box-sizing: border-box;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 350px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    background-color: ivory;
    z-index: 101;
}

.UserMobileEditMenu_select__DnmCS {
    width: 85px;
    height: 24px;
    font-size: 1rem;
}

.UserMobileEditMenu_hours__2CvhX {
    box-sizing: border-box;
    width: 70px;
    font-size: 1rem;
}

.UserMobileEditMenu_inner-container__3byEW {
    display: flex;
}

.UserMobileEditMenu_inner-container__3byEW > div {
    width: 85px;
}

.UserMobileEditMenu_buttons__1Jckd {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.UserOld_container__-NMty {
    display: flex;
    margin-bottom: 5px;
    align-items: center;
}

.UserOld_big-input__1DLuy {
    width: 250px;
}

.UserOld_small-input__3r1C2 {
    width: 80px;
    height: 26px;
    text-align: center;
    font-size: 15px;
}

.UserOld_return-button__2-ctt {
    height: 26px;
    font-size: 17px;
}

@media screen and (max-width: 1365px) {
    .UserOld_responsive__14pu_ {
        display: none;
    }

    .UserOld_big-input__1DLuy {
        width: 130px;
    }
}

.DayPicker {
    font-size: 1rem;
    background: white;
    border-radius: 20px;
    border: 1px solid black;
    display: inline-block;
}

.DayPicker-TodayButton {
    width: 100%;
}

.DayPicker-Day {
    border-radius: 0;
}

.DayPicker-Month {
    border-collapse: separate;
}

.DayPicker-WeekNumber {
    outline: none;
}

/* .DayPicker-Day--hoverRange {
    background-color: #EFEFEF !important;
}

.DayPicker-Day--selectedRange {
    background-color: #fff7ba !important;
    border-top-color: #FFEB3B;
    border-bottom-color: #FFEB3B;
    border-left-color: #fff7ba;
    border-right-color: #fff7ba;
}

.DayPicker-Day--selectedRangeStart {
    border-left: 1px solid #FFEB3B;
}

.DayPicker-Day--selectedRangeEnd {
    border-right: 1px solid #FFEB3B;
} */

.DayPicker-Day--selectedRange:not(.DayPicker-Day--outside).DayPicker-Day--selected, .DayPicker-Day--hoverRange:not(.DayPicker-Day--outside).DayPicker-Day--selected {
    color: black;
}

.DayPicker-Day--weekends:not(.DayPicker-Day--workingDays) {
    background-color: rgb(226, 226, 226);
    color: rgb(202, 0, 0) !important;
}

.DayPicker-Day--holidays:not(.DayPicker-Day--workingDays) {
    background-color: rgb(226, 226, 226);
    color: rgb(202, 0, 0) !important;
}

.UserOvertimeAndConfirmation_container__1Scg2 {
  display:  flex;
  grid-gap: 20px;
  gap: 20px;
}

.UserOvertimeAndConfirmation_headers__2vdRd {
  display: flex;
}

.UserOvertimeAndConfirmation_headers__2vdRd > div {
  width: 140px;
}

.UserOvertimeAndConfirmation_headers__2vdRd > *:nth-child(1) {
  margin-left: 0.2rem;
  width: 250px;
}

.UserOvertimeAndConfirmation_wrapper__1w06k {
  height: calc(100vh - 190px);
  overflow-y: scroll;
  margin-top: 3px;
}

.UserOvertimeAndConfirmation_week__1r8Wp {
  margin-right: 5px;
}

.UserOvertimeAndConfirmation_button__1aObl {
  cursor: pointer;
}

.UserRow_container__1ZxHB {
    display: flex;
    margin-bottom: 5px;
    align-items: center;
}

.UserRow_wrapper__1xcrH {
    display: flex;
}

.UserRow_big-input__33SVo {
    height: 26px;
    width: 250px;
}

.UserRow_small-input__3vgqp {
    width: 30px;
    height: 26px;
    text-align: center;
    font-size: 0.8rem;
}

.UserRow_changed__1v31z {
    border-color: red !important;
}

@media screen and (max-width: 1365px) {
    .UserRow_big-input__33SVo {
        width: 130px;
    }
    
    .UserRow_small-input__3vgqp {
        width: 50px;
    }
}

.StatusField_status__1IBmC {
  height: 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100px;
  min-width: 100px;
  border-radius: 10px;
  margin: 0 5px;
  cursor: pointer;
}

.StatusField_confirmed__e_j0g {
  background: rgb(138, 216, 122);
}

.StatusField_not-confirmed__36OA2 {
  background: lightcoral;
}

.StatusField_confirmed__e_j0g:hover, .StatusField_not-confirmed__36OA2:hover {
  background: khaki;
}

.StatusField_locked__3UStp {
  background: khaki;
}

.StatusField_locked__3UStp:hover {
  background: rgb(184, 192, 184);
}

.StatusField_text__ZCjHP {
  font-size: 0.8rem;
  margin: auto;
}

.Users_container__3sfo2 {
    margin: 20px;
}

.Users_tabs__17dRn {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}

.Users_tabs__17dRn > div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    font-size: 1.2rem;
    background-color: rgb(99, 199, 202);
    width: 300px;
    border: 1px solid rgb(7, 83, 88);
    cursor: pointer;
}

.Users_tabs__17dRn > div:hover {
    background-color: rgb(115, 224, 228);
}

.Users_tabs__17dRn .Users_selected__1V12z {
    background-color: rgb(115, 224, 228);
}

.Users_button__kZdPy {
    margin-bottom: 5px;
}

.Users_headers__11GLa {
    display: flex;
}

.Users_headers__11GLa > *:nth-child(1) {
    margin-left: 0.2rem;
    width: 250px;
}

.Users_headers__11GLa > *:nth-child(2) {
    width: 80px;
}

.Users_headers__11GLa > *:nth-child(3) {
    width: 250px;
}

.Users_headers__11GLa > *:nth-child(4) {
    width: 100px;
}

@media screen and (max-width: 1365px) {
    .Users_container__3sfo2 {
        flex-direction: column-reverse;
        margin: 10px;
    }

    .Users_responsive__FyZY6 {
        display: none;
    }

    .Users_users__1kvO6 {
        margin-top: 20px;
    }
}

.ErrorWrapper_error-container__2vCrj {
    width: 100%;
    bottom: 1.5rem;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10000;
}
.ErrorMessage_container__1V3fc {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(239, 105, 110, 0.95);
    padding: 2rem;
    margin: 0.3rem;
    min-width: 50%;
    max-width: 70%;
    overflow-x: auto;
    border: 1px solid black;
    border-radius: 0.3rem;
    position: relative;
    z-index: 10000;
}

.ErrorMessage_btn-close__3ur4n {
    position: absolute;
    top: 0;
    right: 6px;
    cursor: pointer;
}
