.container {
    display: flex;
    flex-direction: column;
}

.filter-input {
    display: flex;
    margin: 20px 0 20px 50px;
}

.lists {
    display: flex;
    flex-direction: row;
}

.list {
    display: flex;
    flex-direction: column;
    width: 300px;
    margin-left: 50px;
}

.list-options {
    width: 100%;
    height: 300px;
    border: solid 1px;
    border-radius: 5px;
    overflow: hidden;
    box-sizing: border-box;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.list-options-selected {
    width: 100%;
    height: 300px;
    border: solid 1px;
    border-radius: 5px;
    overflow-y: scroll;
    box-sizing: border-box;
}

.list-options-selected::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}
.list-options-selected::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
}
.list-options-selected::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
}
.list-options-selected::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.4);
}
.list-options-selected::-webkit-scrollbar-thumb:active {
    background: rgba(0, 0, 0, 0.9);
}

.input {
    margin: 0 20px;
    padding-left: 20px;
    width: 50px;
    transition: 0.4s ease-in-out;
    height: 1.2rem;
}

.input:focus {
    width: 50%;
}

.list-label {
    margin-bottom: 10px;
}

.filter {
    font-style: italic;
}

.checkbox-list {
    width: 295px !important;
    overflow-x: hidden !important;
    scrollbar-width: thin;
}

.checkbox-list::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}
.checkbox-list::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
}
.checkbox-list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
}
.checkbox-list::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.4);
}
.checkbox-list::-webkit-scrollbar-thumb:active {
    background: rgba(0, 0, 0, 0.9);
}
