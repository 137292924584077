.DayPicker {
    font-size: 1rem;
    background: white;
    border-radius: 20px;
    border: 1px solid black;
    display: inline-block;
}

.DayPicker-TodayButton {
    width: 100%;
}

.DayPicker-Day {
    border-radius: 0;
}

.DayPicker-Month {
    border-collapse: separate;
}

.DayPicker-WeekNumber {
    outline: none;
}

/* .DayPicker-Day--hoverRange {
    background-color: #EFEFEF !important;
}

.DayPicker-Day--selectedRange {
    background-color: #fff7ba !important;
    border-top-color: #FFEB3B;
    border-bottom-color: #FFEB3B;
    border-left-color: #fff7ba;
    border-right-color: #fff7ba;
}

.DayPicker-Day--selectedRangeStart {
    border-left: 1px solid #FFEB3B;
}

.DayPicker-Day--selectedRangeEnd {
    border-right: 1px solid #FFEB3B;
} */

.DayPicker-Day--selectedRange:not(.DayPicker-Day--outside).DayPicker-Day--selected, .DayPicker-Day--hoverRange:not(.DayPicker-Day--outside).DayPicker-Day--selected {
    color: black;
}

.DayPicker-Day--weekends:not(.DayPicker-Day--workingDays) {
    background-color: rgb(226, 226, 226);
    color: rgb(202, 0, 0) !important;
}

.DayPicker-Day--holidays:not(.DayPicker-Day--workingDays) {
    background-color: rgb(226, 226, 226);
    color: rgb(202, 0, 0) !important;
}
