.container {
    display: flex;
    flex-direction: column;
}

.column-names {
    display: flex;
    margin-left: 10px;
    max-width: calc(100% - 343px);
}

.task {
    display: flex;
    margin-left: 10px;
}

.column {
    width: 180px;
    height: 21px;
    flex-grow: 1;
    overflow: hidden;
    box-sizing: border-box;
}

.left {
    display: flex;    
    flex-grow: 1;
    margin-right: 10px;
}

.right {
    display: flex;
}

.buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.edit-button, .cancel-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 21px;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    outline: inherit;
}

.edit-button {
    width: 20px;
}

.edit-button:hover:enabled, .cancel-button:hover:enabled {
    background-color: darkgray;
    border-radius: 5px;
}

.edit-button:disabled, .cancel-button:disabled {
    background-color: none;
}

.remove-button {
    text-align: center;
}

.mobile {
    display: none;
}

.transparent {
    position: fixed;
    left: 0;
    top: 0;
    background-color: black;
    opacity: 0.3;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.description-container {
    position: fixed;
    box-sizing: border-box;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 450px;
    max-height: 80%;
    overflow-y: scroll;
    border-radius: 10px;
    padding: 1rem;
    background-color: ivory;
    z-index: 101;
}

@media screen and (max-width: 1850px) {
    .column {
        width: 155px;
    }
}

@media screen and (max-width: 1680px) {
    .column {
        width: 130px;
    }
}

@media screen and (max-width: 1520px) {
    .column {
        width: 104px;
    }
}

@media screen and (max-width: 1365px) {
    .column {
        width: 40px;
    }

    .desktop {
        display: none;
    }

    .mobile {
        display: initial;
    }
}
