.container {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-right: 11px;
}

.text {
    margin-right: 10px;
}

.buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.edit-button, .cancel-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 21px;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    outline: inherit;
}

.edit-button {
    width: 20px;
}

.edit-button:hover:enabled, .cancel-button:hover:enabled {
    background-color: darkgray;
    border-radius: 5px;
}

.edit-button:disabled, .cancel-button:disabled {
    background-color: none;
}

.remove-button {
    text-align: center;
    width: 25px;
}

.placeholder {
    margin-right: 25px;
}

.confirm {
    display: flex;
    justify-content: flex-end;
    margin-right: 45px;
    width: 100%;
}

.confirm > button {
    width: 130px;
}

.confirm > .remove-confirmation {
    width: 30px;
}
