.title {
    position: absolute;
    top: -20px;
}

.input {
    box-sizing: border-box;
    width: 40px;
    height: 20px;
}

.mobile {
    position: relative;
}

.transparent {
    position: fixed;
    left: 0;
    top: 0;
    background-color: black;
    opacity: 0.3;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.container {
    position: fixed;
    box-sizing: border-box;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    height: 100px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    padding: 1rem;
    background-color: ivory;
    z-index: 101;
}

.bad-input {
    background-color: lightcoral;
}
