.option-container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.data-fetching {
    text-align: center;
    margin: 2rem 0;
    font-size: 2.5rem;
}