.container {
  display:  flex;
  gap: 20px;
}

.headers {
  display: flex;
}

.headers > div {
  width: 140px;
}

.headers > *:nth-child(1) {
  margin-left: 0.2rem;
  width: 250px;
}

.wrapper {
  height: calc(100vh - 190px);
  overflow-y: scroll;
  margin-top: 3px;
}

.week {
  margin-right: 5px;
}

.button {
  cursor: pointer;
}
