.button {
    margin-left: 20px;
}

.alert {
    background-color: lightcoral;
    border-radius: 5px;
    padding: 5px;
    margin-left: 20px;
    margin-top: 5px;
    position: absolute;
}

.user {
    display: flex;
}

.user > input {
    height: 20px;
}

@media screen and (max-width: 1365px) {
    .container {
        display: none;
    }
}
